import { jsx as _jsx } from "react/jsx-runtime";
import { DSThemeProvider } from '@securecodewarrior/design-system-react';
import { featureFlags } from '../../../common';
import { QueryProvider } from '../../queryClient';
import { usePlatformContext } from '../../platformContext';
import { usePlatformNavigate } from '../../navigation';
import { AdminErrorProvider } from '../common/utils/AdminErrorContext';
import { TeamProfile } from './TeamProfile';
import { useParams } from 'react-router-dom';
export const TeamProfileRoute = () => {
    var _a, _b, _c, _d;
    const navigate = usePlatformNavigate();
    const ctx = usePlatformContext();
    const urlParam = useParams();
    const newTeamManagementPageVisible = ctx.flagsEnabled.includes(featureFlags.NEW_USER_MANAGEMENT_PAGE) &&
        (((_b = (_a = ctx.sessionUser) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b.includes('company admin')) || ((_d = (_c = ctx.sessionUser) === null || _c === void 0 ? void 0 : _c.roles) === null || _d === void 0 ? void 0 : _d.includes('team manager')));
    if (!newTeamManagementPageVisible || !urlParam.teamId) {
        navigate.toState('home');
        return;
    }
    return (_jsx(QueryProvider, { children: _jsx(DSThemeProvider, { withBackground: true, theme: "light", children: _jsx(AdminErrorProvider, { children: _jsx(TeamProfile, { teamId: urlParam.teamId }) }) }) }));
};
