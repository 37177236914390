var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useReducer, useState, useRef, useEffect } from 'react';
import { sortRoles } from '../../common/utils';
import { useUserDetails } from './useUserDetails';
const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_FIRST_NAME':
            return Object.assign(Object.assign({}, state), { firstName: action.value });
        case 'SET_LAST_NAME':
            return Object.assign(Object.assign({}, state), { lastName: action.value });
        case 'SET_EMAIL':
            return Object.assign(Object.assign({}, state), { email: action.value });
        case 'SET_ROLE':
            return Object.assign(Object.assign({}, state), { role: action.value });
        case 'SET_TEAM':
            return Object.assign(Object.assign({}, state), { _tid: action.value });
        case 'SET_TAGS':
            return Object.assign(Object.assign({}, state), { tags: action.value });
        default:
            return state;
    }
};
const createInitialState = (user) => {
    var _a, _b;
    return {
        firstName: user === null || user === void 0 ? void 0 : user.properties.profile.name.first,
        lastName: user === null || user === void 0 ? void 0 : user.properties.profile.name.last,
        email: user === null || user === void 0 ? void 0 : user.email,
        role: sortRoles((_a = user === null || user === void 0 ? void 0 : user.roles) !== null && _a !== void 0 ? _a : [])[0],
        _tid: (_b = user === null || user === void 0 ? void 0 : user.properties._tid) !== null && _b !== void 0 ? _b : null,
        tags: user === null || user === void 0 ? void 0 : user.properties.tags,
    };
};
const createUpdateData = (state, user) => {
    var _a, _b, _c, _d;
    const properties = Object.assign(Object.assign({}, user.properties), { profile: Object.assign(Object.assign({}, user.properties.profile), { name: {
                first: (_a = state.firstName) !== null && _a !== void 0 ? _a : user.properties.profile.name.first,
                last: (_b = state.lastName) !== null && _b !== void 0 ? _b : user.properties.profile.name.last,
                middle: user.properties.profile.name.middle,
            } }), _tid: state._tid, tags: (_c = state.tags) !== null && _c !== void 0 ? _c : user.properties.tags });
    const userRoles = state.role === 'company admin'
        ? ['company admin']
        : state.role === 'team manager'
            ? ['team manager', 'developer']
            : ['developer'];
    return Object.assign(Object.assign({}, user), { email: (_d = state.email) !== null && _d !== void 0 ? _d : user.email, roles: userRoles, properties });
};
export const useUpdateUser = (id) => {
    const { user, updateUser } = useUserDetails(id);
    const [state, baseDispatch] = useReducer(reducer, user, createInitialState);
    const [dirty, setDirty] = useState(false);
    const originalStateRef = useRef(null);
    useEffect(() => {
        if (user) {
            originalStateRef.current = createInitialState(user);
        }
    }, [user]);
    const dispatch = (action) => {
        baseDispatch(action);
        setDirty(true);
    };
    const setFirstName = (e) => dispatch({ type: 'SET_FIRST_NAME', value: e.currentTarget.value });
    const setLastName = (e) => dispatch({ type: 'SET_LAST_NAME', value: e.currentTarget.value });
    const setEmail = (e) => dispatch({ type: 'SET_EMAIL', value: e.currentTarget.value });
    const setRole = (value) => dispatch({ type: 'SET_ROLE', value: value });
    const setTeam = (id) => dispatch({ type: 'SET_TEAM', value: id });
    const setTags = (value) => dispatch({ type: 'SET_TAGS', value });
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!user || !dirty)
            return;
        return yield updateUser(createUpdateData(state, user));
    });
    return {
        state,
        dirty,
        originalState: originalStateRef.current,
        actions: {
            setFirstName,
            setLastName,
            setEmail,
            setRole,
            setTeam,
            setTags,
            handleSave,
        },
    };
};
