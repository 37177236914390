import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Tooltip } from '@securecodewarrior/design-system-react/lib/wanda';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { useState } from 'react';
import { BulkActionsMenu, BulkActionsMenuItem } from '../../styled/BulkActionsMenu.styled';
import { useTranslation } from 'react-i18next';
import { BulkActionType } from '../../hooks/useBulkActions';
import { DeleteIcon } from '../../../../common/icons';
import { userManagementAnalytics } from '../../UserManagement.analytics';
import { usePlatformContext } from '../../../../platformContext';
export const BulkActionsSelection = ({ selectedUserIds, handleBulkAction }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = userManagementAnalytics(logAnalyticsEvent);
    const menuActions = [
        { type: BulkActionType.CHANGE_TEAMS, label: t('bulkActionsMenu.changeTeams') },
        { type: BulkActionType.MANAGE_TAGS, label: t('bulkActionsMenu.manageTags'), divider: true },
        { type: BulkActionType.REINVITE_USERS, label: t('bulkActionsMenu.reinviteUsers') },
        { type: BulkActionType.ENABLE_USERS, label: t('bulkActionsMenu.enableUsers') },
        { type: BulkActionType.DISABLE_USERS, label: t('bulkActionsMenu.disableUsers'), divider: true },
        { type: BulkActionType.RESET_PASSWORDS, label: t('bulkActionsMenu.resetPasswords') },
        { type: BulkActionType.CHANGE_ROLES, label: t('bulkActionsMenu.changeRoles'), divider: true },
        {
            type: BulkActionType.DELETE_USERS,
            label: t('bulkActionsMenu.deleteUsers'),
            icon: _jsx(DeleteIcon, { sx: { fontSize: 16 } }),
            danger: true,
        },
    ];
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        analytics.onClickBulkActionsButton(selectedUserIds.length);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleMenuItemClick = (action) => {
        if (selectedUserIds.length === 0) {
            return;
        }
        handleBulkAction(action.type);
        handleClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: t('bulkActionsTooltip'), arrow: true, disableHoverListener: selectedUserIds.length > 0, children: _jsx("span", { children: _jsx(Button, { variant: "outlined", endIcon: _jsx(KeyboardArrowDown, {}), onClick: handleClick, disabled: selectedUserIds.length === 0, "data-testid": "bulk-actions-button", "aria-controls": menuOpen ? 'bulk-actions-menu' : undefined, "aria-haspopup": "true", "aria-expanded": menuOpen ? 'true' : undefined, children: t('bulkActions') }) }) }), _jsx(BulkActionsMenu, { id: "bulk-actions-menu", anchorEl: anchorEl, open: menuOpen, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                }, MenuListProps: {
                    'aria-labelledby': 'bulk-actions-button',
                    dense: true,
                }, children: menuActions.map((action) => (_jsxs("div", { children: [_jsxs(BulkActionsMenuItem, { onClick: () => handleMenuItemClick(action), hasIcon: !!action.icon, sx: action.danger ? { color: 'error.main' } : undefined, children: [action.icon, action.label] }), action.divider && _jsx(Divider, {})] }, action.type))) })] }));
};
