var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { DataGrid } from '@securecodewarrior/design-system-react/lib/labs';
import { useTranslation } from 'react-i18next';
import { BulkActionsSelection } from '../molecules/BulkActionsSelection';
import EditUserModal from '../molecules/EditUserModal';
import { useDataGridData } from '../../hooks/useDataGridData';
import { useUserFilters } from '../../hooks/useUserFilters';
import { UserFiltersToolbar } from '../molecules/UserFiltersToolbar';
import { TagCell } from '../molecules/TagCell';
import { SelectionModal, } from '../../../../../common/components/molecules/SelectionModal/SelectionModal';
import { useBulkActions } from '../../hooks/useBulkActions';
import { BulkActionModal } from '../molecules/BulkActionModal';
import { useUserSingleAction } from '../../hooks/useUserSingleAction';
import { SingleUserActionMenu } from '../molecules/SingleUserActionMenu';
import { SingleActionModal } from '../molecules/SingleActionModal';
import { defaultColumnVisibilityModel } from '../../utils/createGridProps';
import { formatDateLongMonth } from '../../../../dateHelper';
import { usePlatformContext } from '../../../../platformContext';
import { userManagementAnalytics } from '../../UserManagement.analytics';
const teamColumns = [
    { field: 'name', headerName: 'Team Name', flex: 1 },
    { field: 'createdAt', headerName: 'Created At', flex: 1 },
    { field: 'teamSize', headerName: 'Team Size', flex: 1 },
];
const tagColumns = [{ field: 'name', headerName: 'Tag Name', flex: 1 }];
const createTeamItems = (teams) => teams.map((team) => ({
    id: team._id,
    name: team.name,
    createdAt: team.createdAt,
    teamSize: team.teamSize,
}));
export const UsersDataGrid = () => {
    var _a;
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.dataGrid' });
    const { users, tags, teams, error, isQueryError, isLoading, gridProps, refetch } = useDataGridData();
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = userManagementAnalytics(logAnalyticsEvent);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { selectedUserIds, setSelectedUserIds, modalState, closeModal, handleActionConfirm, handleBulkAction } = useBulkActions();
    const { userId, actionType, clearAction, confirmAction } = useUserSingleAction();
    const { allTags, filterOptions, filterState, filterActions } = useUserFilters(tags);
    const [fieldVisibility, setFieldVisibility] = useState(defaultColumnVisibilityModel);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const handleFieldVisibilityChange = (newModel) => {
        setFieldVisibility(newModel);
    };
    const openEditModal = ({ row: user }) => {
        setSelectedUserId(user.id);
        setIsModalOpen(true);
        analytics.onClickEditUser();
    };
    const closeEditModal = () => {
        setSelectedUserId(null);
        setIsModalOpen(false);
    };
    const handleRowSelectionModelChange = (newSelection) => {
        setSelectedUserIds(newSelection);
        setRowSelectionModel(newSelection);
    };
    const handleBulkActionConfirm = (props) => __awaiter(void 0, void 0, void 0, function* () {
        yield handleActionConfirm(modalState.actionType, props);
        setSelectedUserIds([]);
        setRowSelectionModel([]);
    });
    const handleBulkActionClick = (action) => {
        handleBulkAction(action);
        analytics.onClickBulkAction(action);
    };
    useEffect(() => {
        refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if (!isLoading) {
            analytics.onViewUserManagementPage(users.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);
    if (isQueryError && error instanceof Error) {
        return _jsxs(Stack, { children: ["Error: ", (_a = error === null || error === void 0 ? void 0 : error.message) !== null && _a !== void 0 ? _a : 'Unknown error'] });
    }
    const columns = [
        { field: 'name', headerName: `${t('name')}`, hideable: false, flex: 1 },
        { field: 'email', headerName: `${t('email')}`, hideable: false, flex: 1 },
        { field: 'team', headerName: `${t('team')}`, flex: 1 },
        {
            field: 'role',
            headerName: `${t('role')}`,
            flex: 1,
            valueFormatter: (value) => (value ? t(`dataGridValues.role.${value}`) : ''),
        },
        {
            field: 'status',
            headerName: `${t('status')}`,
            flex: 1,
            valueFormatter: (value) => t(`dataGridValues.status.${value}`),
        },
        {
            field: 'lastLogin',
            headerName: `${t('lastLogin')}`,
            align: 'right',
            flex: 1,
            valueFormatter: (date) => (date ? formatDateLongMonth(date) : date),
        },
        {
            field: 'registered',
            headerName: `${t('registeredDate')}`,
            align: 'right',
            flex: 1,
            valueFormatter: (date) => (date ? formatDateLongMonth(date) : date),
        },
        {
            field: 'lastInvited',
            headerName: `${t('lastInvitedDate')}`,
            align: 'right',
            flex: 1,
            valueFormatter: (date) => (date ? formatDateLongMonth(date) : date),
        },
        {
            field: 'inviteReminders',
            headerName: `${t('lastRemindedDate')}`,
            align: 'right',
            flex: 1,
            valueFormatter: (date) => (date ? formatDateLongMonth(date) : date),
        },
        { field: 'inviteBounce', headerName: `${t('invitationBounced')}`, flex: 1 },
        {
            field: 'tags',
            headerName: `${t('tags')}`,
            flex: 1,
            renderCell: (params) => _jsx(TagCell, { tags: params.value || [] }),
            filterOperators: [
                {
                    label: 'contains any',
                    value: 'isAnyOf',
                    getApplyFilterFn: (filterItem) => {
                        const { value: selectedTags } = filterItem;
                        return (userTags) => {
                            if (userTags.length === 0) {
                                return false;
                            }
                            return userTags.some((tag) => selectedTags.includes(tag));
                        };
                    },
                },
            ],
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            sortable: false,
            hideable: false,
            filterable: false,
            minWidth: 50,
            maxWidth: 50,
            renderCell: ({ row }) => _jsx(SingleUserActionMenu, { userId: row.id, disabled: !!selectedUserIds.length }),
        },
    ];
    const teamItems = createTeamItems(teams);
    const tagItems = allTags.map((tag) => ({ id: tag, name: tag }));
    const companyAdminEmails = selectedUserIds.reduce((acc, id) => {
        const user = users.find((user) => user.id === id);
        return (user === null || user === void 0 ? void 0 : user.role) === 'company admin' ? [...acc, user.email] : acc;
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(DataGrid, Object.assign({ variant: "pagination", dataSource: { data: users, loading: isLoading }, name: "users-list", columns: columns, getCellClassName: (params) => {
                    if (params.row.status === 'disabled') {
                        return 'scwUserDisabled';
                    }
                    return '';
                }, onRowClick: openEditModal, rowSelectionModel: rowSelectionModel, onRowSelectionModelChange: handleRowSelectionModelChange, columnVisibilityModel: fieldVisibility, onColumnVisibilityModelChange: handleFieldVisibilityChange, filterModel: filterState.filterModel, onFilterModelChange: (model) => {
                    filterActions.setFilterModel(model);
                }, checkboxSelectionVisibleOnly: selectedUserIds.length !== users.length, slotProps: {
                    pagination: {
                        rowsPerPageOptions: [25, 50, 100, 200, 500],
                    },
                    toolbar: {
                        csvOptions: { disableToolbarButton: true },
                        selectionComponents: (_jsx(BulkActionsSelection, { selectedUserIds: selectedUserIds, handleBulkAction: handleBulkActionClick })),
                        actionComponents: (_jsx(UserFiltersToolbar, { filterOptions: filterOptions, filterState: filterState, filterActions: filterActions, allTags: allTags })),
                    },
                }, sx: (theme) => ({
                    '& .MuiDataGrid-cell:focus-within': { outline: 'none' },
                    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': { width: 'unset', height: 'unset' },
                    '& .MuiDataGrid-row': {
                        cursor: 'pointer',
                        '&:hover': { backgroundColor: theme.palette.container.fill.card2 },
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root input': { marginTop: '0px' },
                    '& .MuiDataGrid-cell.scwUserDisabled:not([data-field="tags"])': { fontStyle: 'italic', color: 'text.soft' },
                    '& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitle': { whiteSpace: 'nowrap' },
                }) }, gridProps)), _jsx(SelectionModal, { open: filterState.isTeamModalOpen, onClose: filterActions.handleTeamModalClose, onSave: (value) => {
                    filterActions.handleTeamSelect(value);
                    const teamNames = value.length > 0 ? teams.filter((team) => value.includes(team._id)).map((team) => team.name) : value;
                    analytics.onFilterUserManagement({ teams: teamNames });
                }, items: teamItems, selectedIds: filterState.selectedTeamIds, title: "Select teams", description: "Select one or more teams to filter the users", columns: teamColumns }), _jsx(SelectionModal, { open: filterState.isTagModalOpen, onClose: filterActions.handleTagModalClose, onSave: (tags) => {
                    filterActions.handleTagSelect(tags);
                    analytics.onFilterUserManagement({ tags });
                }, items: tagItems, selectedIds: filterState.selectedTagIds, title: "Select tags", description: "Select one or more tags to filter the users", columns: tagColumns }), selectedUserId && _jsx(EditUserModal, { id: selectedUserId, isOpen: isModalOpen, onClose: closeEditModal }), userId && actionType && (_jsx(SingleActionModal, { userId: userId, onClose: clearAction, onConfirm: confirmAction, action: actionType })), _jsx(BulkActionModal, { actionType: modalState.actionType, selectedCount: modalState.selectedCount, updatedCount: modalState.updatedCount, onClose: closeModal, onConfirm: handleBulkActionConfirm, selectedUserIds: selectedUserIds, goneAsync: modalState.goneAsync, currentUserSelected: modalState.currentUserSelected, companyAdminEmails: companyAdminEmails })] }));
};
