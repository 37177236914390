// Action type mappings
const singleActionMap = {
    USER_ENABLE: 'enable',
    USER_DISABLE: 'disable',
    USER_RESET_PASSWORD: 'reset_password',
    USER_DELETE: 'delete',
    USER_RESEND: 'reinvite',
};
const bulkActionMap = {
    changeTeams: 'change_teams',
    manageTags: 'manage_tags',
    reinviteUsers: 'reinvite_users',
    enableUsers: 'enable_users',
    disableUsers: 'disable_users',
    resetPasswords: 'reset_passwords',
    changeRoles: 'change_roles',
    deleteUsers: 'delete_users',
};
export const userManagementAnalytics = (sendEvent, scope = 'userManagement') => ({
    // Page View Event
    onViewUserManagementPage: (numberOfUsersLoaded) => {
        sendEvent({ event: 'view_user_management_page', scope, number_of_users_loaded: numberOfUsersLoaded });
    },
    onFilterUserManagement: ({ search, status, role, teams, tags, }) => {
        sendEvent({
            event: 'filter_user_management',
            scope,
            search,
            status,
            role,
            teams,
            tags,
        });
    },
    // Bulk Actions Events
    onClickBulkActionsButton: (numberOfUsersSelected) => {
        sendEvent({ event: 'click_bulk_actions', scope, number_users_selected: numberOfUsersSelected });
    },
    onClickBulkAction: (action) => {
        sendEvent({ event: `click_bulk_actions_${bulkActionMap[action]}`, scope });
    },
    // Bulk Action Confirmation/Cancellation Events
    onConfirmBulkAction: (action, numberOfUsersEdited, updateType) => {
        sendEvent({
            event: `confirm_bulk_action_${bulkActionMap[action]}`,
            scope,
            number_of_users_edited: numberOfUsersEdited,
            bulk_action_tag_update_type: updateType,
        });
    },
    onConfirmManageTagsBulkAction: (numberOfUsersEdited, updateType) => {
        sendEvent({
            event: `confirm_bulk_action_manage_tags`,
            scope,
            number_of_users_edited: numberOfUsersEdited,
            bulk_action_tag_update_type: updateType,
        });
    },
    onCancelBulkAction: (action, numberOfUsersEdited) => {
        sendEvent({
            event: `cancel_bulk_action_${bulkActionMap[action]}`,
            scope,
            number_of_users_edited: numberOfUsersEdited,
        });
    },
    // Single User Actions Events
    onClickSingleUserActions: () => {
        sendEvent({ event: 'click_single_user_actions', scope });
    },
    onClickSingleAction: (action) => {
        sendEvent({ event: `click_single_action_${singleActionMap[action]}`, scope });
    },
    onConfirmSingleAction: (action) => {
        sendEvent({ event: `confirm_single_action_${action}`, scope });
    },
    onCancelSingleAction: (action) => {
        sendEvent({ event: `cancel_single_action_${action}`, scope });
    },
    // Edit User Modal Events
    onClickEditUser: () => {
        sendEvent({ event: 'click_edit_user', scope });
    },
    onConfirmEditUser: (changes) => {
        sendEvent(Object.assign({ event: 'confirm_edit_user', scope }, changes));
    },
    onCancelEditUser: () => {
        sendEvent({ event: 'cancel_edit_user', scope });
    },
    // Add User Modal Events
    onClickAddUsers: () => {
        sendEvent({ event: 'click_add_users', scope });
    },
    onConfirmAddUsers: (data) => {
        sendEvent(Object.assign({ event: 'confirm_add_users', scope }, data));
    },
    onCancelAddUsers: () => {
        sendEvent({ event: 'cancel_add_users', scope });
    },
});
