import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { Password, CheckCircleRounded } from '@mui/icons-material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { UserManagementModal } from '../UserManagementModal';
import { BulkActionsAsyncDialog } from '../BulkActionsAsyncDialog';
import { usePlatformContext } from '../../../../../platformContext';
import { userManagementAnalytics } from '../../../UserManagement.analytics';
import { BulkActionType } from '../../../hooks/useBulkActions';
const ResetPasswordsPending = ({ userCount, onClose, onConfirm }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = userManagementAnalytics(logAnalyticsEvent);
    const handleConfirm = () => {
        analytics.onConfirmBulkAction(BulkActionType.RESET_PASSWORDS, userCount);
        onConfirm();
    };
    const handleCancel = () => {
        analytics.onCancelBulkAction(BulkActionType.RESET_PASSWORDS, userCount);
        onClose();
    };
    return (_jsx(UserManagementModal, { title: t('resetPasswordsDialog.pending.title', { count: userCount }), icon: _jsx(Password, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.soft } }), footer: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Button, { variant: "outlined", onClick: handleCancel, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: handleConfirm, children: t('resetPasswordsDialog.pending.confirm', { count: userCount }) })] }), children: _jsxs(Stack, { alignItems: "center", gap: 2, children: [_jsx(Typography, { align: "center", children: t('resetPasswordsDialog.pending.description') }), _jsx(Typography, { align: "center", children: t('resetPasswordsDialog.pending.warning') })] }) }));
};
const ResetPasswordsSuccess = ({ updatedCount, onClose, userCount }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const notReset = Math.max(userCount - updatedCount, 0);
    return (_jsx(UserManagementModal, { title: t('resetPasswordsDialog.success.title', { count: updatedCount }), icon: _jsx(CheckCircleRounded, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.success } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }), children: notReset ? (_jsx(Typography, { align: "center", children: t('resetPasswordsDialog.success.description', { count: notReset }) })) : null }));
};
export const ResetPasswordsDialog = ({ onClose, onConfirm, userCount, updatedCount, goneAsync, }) => {
    if (goneAsync) {
        return _jsx(BulkActionsAsyncDialog, { onClose: onClose });
    }
    if (updatedCount !== undefined) {
        return _jsx(ResetPasswordsSuccess, { updatedCount: updatedCount !== null && updatedCount !== void 0 ? updatedCount : 0, onClose: onClose, userCount: userCount });
    }
    return _jsx(ResetPasswordsPending, { userCount: userCount, onClose: onClose, onConfirm: onConfirm });
};
