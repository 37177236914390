import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container, Stack, Typography } from '@mui/material';
import { useTeam } from './hooks/useTeam';
import { useTeams } from './hooks/useTeams';
import { Link } from '@securecodewarrior/design-system-react/lib/wanda';
import { ArrowBack } from '@mui/icons-material';
import { Team } from './components/Team';
import { SingleActionProvider } from '../common/context';
import { useTranslation } from 'react-i18next';
export const TeamProfile = ({ teamId }) => {
    const { teams } = useTeams();
    const { team } = useTeam(teamId);
    const teamDetails = teams.find((team) => team._id === teamId);
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.teamProfile' });
    if (!team || !teamDetails) {
        return null;
    }
    return (_jsx(Container, { children: _jsxs(Stack, { rowGap: ({ spacing }) => spacing(10), padding: ({ spacing }) => spacing(12), children: [_jsxs(Stack, { gap: 1, children: [_jsx(Link, { href: "#/team-management", children: _jsxs(Stack, { gap: 1, direction: "row", alignItems: "center", children: [_jsx(ArrowBack, { sx: { fontSize: (theme) => theme.typography.pxToRem(16) } }), " ", t('backToTeamManagement')] }) }), _jsx(Typography, { component: "h2", variant: "h2", children: team.name })] }), _jsx(SingleActionProvider, { children: _jsx(Team, { team: team, teamDetails: teamDetails }) })] }) }));
};
