import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CheckCircleRounded as MuiCheckIcon, Send as MuiSendIcon } from '@mui/icons-material';
import { UserManagementModal } from './UserManagementModal';
import { BulkActionsAsyncDialog } from './BulkActionsAsyncDialog';
import { BulkActionType } from '../../hooks/useBulkActions';
import { usePlatformContext } from '../../../../platformContext';
import { userManagementAnalytics } from '../../UserManagement.analytics';
const BulkReinviteSuccess = ({ onClose, userCount, updatedCount }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const alreadyReinvitedCount = userCount - (updatedCount || 0);
    return (_jsx(UserManagementModal, { title: t('reinviteUsersDialog.successTitle', { count: updatedCount }), icon: _jsx(MuiCheckIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.success } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }), children: alreadyReinvitedCount > 0 && (_jsx(Typography, { children: t('reinviteUsersDialog.alreadyReinvited', {
                count: alreadyReinvitedCount,
            }) })) }));
};
const BulkReinvitePending = ({ onClose, onConfirm, userCount }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = userManagementAnalytics(logAnalyticsEvent);
    const handleConfirm = () => {
        analytics.onConfirmBulkAction(BulkActionType.REINVITE_USERS, userCount);
        onConfirm();
    };
    const handleCancel = () => {
        analytics.onCancelBulkAction(BulkActionType.REINVITE_USERS, userCount);
        onClose();
    };
    return (_jsx(UserManagementModal, { title: t('reinviteUsersDialog.title', { count: userCount }), icon: _jsx(MuiSendIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.soft } }), footer: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Button, { variant: "outlined", onClick: handleCancel, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: handleConfirm, children: t('reinviteUsersDialog.confirm', { count: userCount }) })] }), children: _jsxs(Stack, { alignItems: "center", gap: 2, children: [_jsx(Typography, { align: 'center', children: t('reinviteUsersDialog.description') }), _jsx(Typography, { children: t('reinviteUsersDialog.warning') })] }) }));
};
export const ReinviteUsersDialog = ({ onClose, onConfirm, userCount, updatedCount, goneAsync, }) => {
    return goneAsync === true ? (_jsx(BulkActionsAsyncDialog, { onClose: onClose })) : updatedCount || updatedCount === 0 ? (_jsx(BulkReinviteSuccess, { onClose: onClose, userCount: userCount, updatedCount: updatedCount })) : (_jsx(BulkReinvitePending, { onClose: onClose, userCount: userCount, onConfirm: onConfirm }));
};
