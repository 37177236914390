import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { Card, InsightCard } from '@securecodewarrior/design-system-react/lib/labs';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import InviteUsersModal from '../../user-management/components/organisms/InviteUsersModal';
import { Edit, PeopleAlt, CalendarToday } from '@mui/icons-material';
import { SingleActionModal } from './molecules/SingleActionModal';
import { useTeamSingleAction } from '../hooks/useTeamSingleAction';
import { useTranslation } from 'react-i18next';
import { formatDateShortMonth } from '../../../dateHelper';
const DetailCards = ({ team, teamDetails }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.teamProfile' });
    const maxTeamSize = team.allowances.training.license === 'unlimited'
        ? 0
        : (team.allowances.training.available || 0) +
            (team.allowances.training.reserved || 0) +
            (team.allowances.training.used || 0);
    return (_jsxs(_Fragment, { children: [_jsx(InsightCard, { label: t('teamSize'), value: { value: teamDetails.teamSize, total: maxTeamSize }, badgeProps: {
                    icon: PeopleAlt,
                    color: 'gold',
                    iconSize: 'large',
                } }), team.activationDate && (_jsx(InsightCard, { label: t('activationDate'), value: formatDateShortMonth(team.activationDate), badgeProps: {
                    icon: CalendarToday,
                    color: 'blue',
                    iconSize: 'large',
                } })), team.expirationDate && (_jsx(InsightCard, { label: t('expirationDate'), value: formatDateShortMonth(team.expirationDate), badgeProps: {
                    icon: CalendarToday,
                    color: 'pink',
                    iconSize: 'large',
                } }))] }));
};
export const Team = ({ team, teamDetails }) => {
    const { teamId, actionType, clearAction, confirmAction, setActionType, setTeamId } = useTeamSingleAction();
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.teamProfile' });
    const handleEditTeam = () => {
        setActionType('TEAM_EDIT');
        setTeamId(team._id);
    };
    return (_jsxs(Stack, { gap: ({ spacing }) => spacing(10), children: [_jsx(Stack, { gap: 6, sx: { display: 'grid', gridTemplateColumns: { xs: '1fr', md: 'repeat(3, 1fr)' } }, children: _jsx(DetailCards, { team: team, teamDetails: teamDetails }) }), _jsxs(Stack, { direction: 'row', justifyContent: 'space-between', children: [_jsx(Typography, { variant: "h5", children: t('teamMembers') }), _jsxs(Stack, { direction: "row", gap: 2, children: [_jsx(Button, { variant: "outlined", startIcon: _jsx(Edit, {}), onClick: handleEditTeam, children: t('editTeam') }), _jsx(InviteUsersModal, {})] })] }), _jsxs(Card, { sx: ({ spacing }) => ({ padding: spacing(7) }), children: [_jsx(_Fragment, { children: "Team" }), teamId && actionType && (_jsx(SingleActionModal, { teamId: teamId, onClose: clearAction, onConfirm: (payload) => confirmAction(payload), action: actionType }))] })] }));
};
