import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Typography, Stack } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { CheckCircleRounded as MuiCheckIcon, CheckCircleOutlineRounded as MuiCheckCircleOutlineIcon, } from '@mui/icons-material';
import { UserManagementModal } from './UserManagementModal';
import { BulkActionsAsyncDialog } from './BulkActionsAsyncDialog';
import { BulkActionType } from '../../hooks/useBulkActions';
import { userManagementAnalytics } from '../../UserManagement.analytics';
import { usePlatformContext } from '../../../../platformContext';
const isPlural = (num) => {
    return num === 1 ? 'one' : 'more';
};
const BulkEnableUsersPending = ({ onClose, onConfirm, userCount }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = userManagementAnalytics(logAnalyticsEvent);
    const handleConfirm = () => {
        analytics.onConfirmBulkAction(BulkActionType.ENABLE_USERS, userCount);
        onConfirm();
    };
    const handleCancel = () => {
        analytics.onCancelBulkAction(BulkActionType.ENABLE_USERS, userCount);
        onClose();
    };
    return (_jsx(UserManagementModal, { title: t(`enableUsersDialog.title_${isPlural(userCount)}`, { count: userCount }), icon: _jsx(MuiCheckCircleOutlineIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.soft } }), footer: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Button, { variant: "outlined", onClick: handleCancel, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: handleConfirm, children: t(`enableUsersDialog.confirm_${isPlural(userCount)}`) })] }), children: _jsxs(Stack, { alignItems: "center", gap: 2, children: [_jsx(Typography, { children: t('enableUsersDialog.description') }), _jsx(Typography, { children: t('enableUsersDialog.warning') })] }) }));
};
const BulkEnableUsersSuccess = ({ onClose, updatedCount, userCount }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const skippedCount = userCount - (updatedCount || 0);
    return (_jsxs(UserManagementModal, { title: t(`enableUsersDialog.successTitle_${isPlural(updatedCount || 0)}`, { count: updatedCount }), icon: _jsx(MuiCheckIcon, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.success } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }), children: [_jsx(Typography, { children: t('enableUsersDialog.successDescription') }), skippedCount > 0 && (_jsx(Typography, { children: t(`enableUsersDialog.alreadyEnabled_${isPlural(skippedCount)}`, { count: skippedCount }) }))] }));
};
export const EnableUsersDialog = ({ onClose, onConfirm, userCount, updatedCount, goneAsync, }) => {
    if (goneAsync) {
        return _jsx(BulkActionsAsyncDialog, { onClose: onClose });
    }
    if (updatedCount !== undefined) {
        return _jsx(BulkEnableUsersSuccess, { updatedCount: updatedCount, userCount: userCount, onClose: onClose });
    }
    return _jsx(BulkEnableUsersPending, { userCount: userCount, onClose: onClose, onConfirm: onConfirm });
};
