var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography } from '@mui/material';
import { Button } from '@securecodewarrior/design-system-react/lib/wanda';
import { useUsers } from '../../../hooks/useUsers';
import { useState } from 'react';
import { WarningRounded, CheckCircleRounded } from '@mui/icons-material';
import { ActionDialog } from '../../../../common/components/organisms/ActionDialog';
import { useTranslation } from 'react-i18next';
import { usePlatformContext } from '../../../../../platformContext';
import { userManagementAnalytics } from '../../../UserManagement.analytics';
const SingleUserDisablePending = ({ onClose, onConfirm, user }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = userManagementAnalytics(logAnalyticsEvent);
    const handleConfirm = () => {
        analytics.onConfirmSingleAction('disable');
        onConfirm();
    };
    const handleCancel = () => {
        analytics.onCancelSingleAction('disable');
        onClose();
    };
    return (_jsx(ActionDialog, { title: t('singleUserDisableDialog.pending.title', { email: user.email }), icon: _jsx(WarningRounded, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.warning } }), footer: _jsxs(Stack, { direction: "row", justifyContent: "space-between", children: [_jsx(Button, { variant: "outlined", onClick: handleCancel, children: t('common.cancel') }), _jsx(Button, { variant: "contained", onClick: handleConfirm, children: t('singleUserDisableDialog.pending.confirm') })] }), children: _jsx(Typography, { textAlign: "center", children: t('singleUserDisableDialog.pending.description') }) }));
};
const SingleUserDisableSuccess = ({ onClose, user }) => {
    const { t } = useTranslation('react_components', { keyPrefix: 'pages.administration.userManagement' });
    return (_jsx(ActionDialog, { title: t('singleUserDisableDialog.success.title', { email: user.email }), icon: _jsx(CheckCircleRounded, { sx: { fontSize: '56px', color: (theme) => theme.palette.text.status.success } }), footer: _jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: "contained", onClick: onClose, children: t('common.okay') }) }) }));
};
export const SingleUserDisableDialog = ({ onClose, onConfirm, userId }) => {
    const [success, setSuccess] = useState(false);
    const { users } = useUsers();
    const user = users.find((u) => u._id === userId);
    if (!user) {
        return null;
    }
    const handleConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        yield onConfirm();
        setSuccess(true);
    });
    if (success) {
        return _jsx(SingleUserDisableSuccess, { onClose: onClose, user: user });
    }
    return _jsx(SingleUserDisablePending, { onClose: onClose, onConfirm: handleConfirm, user: user });
};
