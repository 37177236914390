import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterDropdown } from '../../../../../common/components/molecules/FilterDropdown/FilterDropdown';
import { SelectionFilterDropdown } from '../../../../../common/components/molecules/SelectionFilterDropdown/SelectionFilterDropdown';
import { usePlatformContext } from '../../../../platformContext';
import { userManagementAnalytics } from '../../UserManagement.analytics';
export const UserFiltersToolbar = ({ filterOptions, filterState, filterActions, allTags, }) => {
    const { statusOptions, roleOptions } = filterOptions;
    const { statusFilter, roleFilter, teamFilter, tagFilter } = filterState;
    const { handleStatusFilterChange, handleRoleFilterChange, handleTeamFilterChange, handleTagFilterChange } = filterActions;
    const { logAnalyticsEvent } = usePlatformContext();
    const analytics = userManagementAnalytics(logAnalyticsEvent);
    return (_jsxs(_Fragment, { children: [_jsx(FilterDropdown, { options: statusOptions, value: statusFilter, onChange: (status) => {
                    handleStatusFilterChange(status);
                    analytics.onFilterUserManagement({ status });
                }, label: "Status", fullWidth: false }), _jsx(FilterDropdown, { options: roleOptions, value: roleFilter, onChange: (role) => {
                    handleRoleFilterChange(role);
                    analytics.onFilterUserManagement({ role });
                }, label: "Role", fullWidth: false }), _jsx(SelectionFilterDropdown, { value: teamFilter, onChange: handleTeamFilterChange, label: "Team", selectedCount: filterState.selectedTeamIds.length, selectLabel: "Select teams", fullWidth: false }), allTags.length > 0 && (_jsx(SelectionFilterDropdown, { value: tagFilter, onChange: handleTagFilterChange, label: "Tag", selectedCount: filterState.selectedTagIds.length, selectLabel: "Select tags", allLabel: "All", fullWidth: false }))] }));
};
